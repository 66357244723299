/**
 * Auth and login actions
 */
import Router from 'next/router'

import { parser } from '@components/form-inputs/constants'
import { EAZE_ORIGIN } from '@helpers/constants'
import getPath from '@helpers/path'
import ROUTES from '@helpers/routes'
import { ERRORS, isStringMatch, noop } from '@helpers/signup'
import { track } from 'analytics'
import api from 'api'
import errorHandler from 'error-handler'
import { handleAddressSubmissionChange, locationFetcher } from 'redux/action-wrappers/location'
import { resetCurrentAction, setAlertTypeVisibility } from 'redux/alert/actions'
import { alertTypes } from 'redux/alert/config/types'
import { isLoggedIn, getUserId, getUserEmail } from 'redux/auth/selectors'
import { fetchCart } from 'redux/cart/actions'
import { setCookie } from 'redux/cookies/actions'
import {
  addressLoaded,
  addressLoading,
  loginLoaded,
  loginLoading,
  mmidImageLoaded,
  mmidImageLoading,
  phoneConfirmLoading,
  phoneConfirmLoaded,
  resetPasswordLoaded,
  resetPasswordLoading,
  signupLoading,
  signupLoaded,
  stateIdUploading,
  stateIdUploaded
} from 'redux/loading/actions'
import { fetchStatus, setRecommendationPhoto, setIdentificationPhoto, fetchUserProfile } from 'redux/profile/actions'
import * as userActions from 'redux/user/actions'

import t from './actionTypes'

export {
  confirmPhoneCode,
  handleSignupLocation,
  login,
  receiveLoginSuccess,
  receiveSignupError,
  requestSignup,
  resetError,
  resetExistingSignupIdentifier,
  resetInvalidLoginAttempt,
  resetInvalidPasswordRecoveryAttempt,
  resetPassword,
  resetPasswordRecoveryIdentifier,
  setInvalidLoginAttempt,
  setInvalidPasswordRecoveryAttempt,
  setPasswordRecoveryIdentifier,
  setPhoneConfirmError,
  signup,
  uploadIdentification,
  verifyAuthToken
}

/**
 * A login request is sent to the server
 * @return {Object} action
 */
function requestLogin() {
  return (dispatch) => {
    dispatch({
      type: t.REQUEST_LOGIN
    })
    dispatch(resetExistingSignupIdentifier())
    dispatch(resetInvalidLoginAttempt())
  }
}

const UNAUTHORIZED = 401
const TOO_MANY_LOGINS = 429 // "enchance your calm (sic)"
/**
 * A login request returned unsuccessfully
 */
export function receiveLoginError(err) {
  return (dispatch) => {
    if (err.statusCode === UNAUTHORIZED) {
      dispatch(setInvalidLoginAttempt())
    } else if (err.statusCode === TOO_MANY_LOGINS) {
      err = 'Please try again in a few moments'
    } else {
      errorHandler(new Error(err.message))
      dispatch({
        type: t.RECEIVE_LOGIN_ERROR,
        err
      })
    }
  }
}

/**
 * A login request returned successfully
 * This action is exported so that other actions can use it.
 * This action should never be triggered by a component directly.
 * Use `login` from a component.
 */
function receiveLoginSuccess(res) {
  return (dispatch) => {
    // Login response doesn't send us firstName and lastName so those are undefined here
    const { xAuthToken, id, email, firstName, lastName, zipCode, mobilePhone } = res
    track('Login.Success', { email })
    dispatch(userActions.setUserTokenAndCookie(xAuthToken))
    dispatch(userActions.setUserBasicAndCookie(id, email, firstName, lastName, zipCode, mobilePhone))
    dispatch({ type: t.RECEIVE_LOGIN_SUCCESS, res })
    dispatch(fetchStatus())
  }
}

/**
 * Handle the login process and stores the auth info
 * @param  {String} username username
 * @param  {String} password password
 * @return {function}        actions for redux-thunk
 */
function login(username, password, redirectUrl = ROUTES.MENU) {
  return (dispatch) => {
    dispatch(requestLogin())
    dispatch(loginLoading())
    api.login(
      {
        email: username,
        password: password
      },
      (err, res) => {
        dispatch(loginLoaded())
        if (err) return dispatch(receiveLoginError(err))

        dispatch(receiveLoginSuccess(res))

        dispatch(setCookie({ juiced: false }))

        if (redirectUrl) {
          // redirectUrl should be in href form: '/menu?slug=fire&cart=123'
          // getPath errors on redirectURL's with query params unless you add a specific route in `helpers/routes` that expresses each query param as a path variable.
          // It then strips those query params (*-_-)
          const url = getPath({ redirectUrl }, {})
          // So unfortunately, we're gonna fork here.
          // TODO: remove use of getPath here, if path matching is needed, use it before input into action.
          if (url && url.href) {
            return Router.push(url.href, url.asPath)
          } else {
            return Router.push(redirectUrl)
          }
        }
      }
    )
  }
}

/**
 * A signup request returned an error
 */
function receiveSignupError(err, email) {
  return {
    type: t.RECEIVE_SIGNUP_ERROR,
    err,
    email
  }
}

function requestSignup() {
  return (dispatch) => {
    dispatch(signupLoading())
    dispatch({
      type: t.REQUEST_SIGNUP
    })
  }
}

/**
 * Reset password
 */
function resetPassword(emailAddress) {
  return (dispatch) => {
    dispatch(resetPasswordLoading())
    dispatch(resetInvalidPasswordRecoveryAttempt())
    dispatch(setAlertTypeVisibility(alertTypes.NON_EXISTENT_EMAIL))

    api.requestResetPassword(
      {
        emailAddress
      },
      (err, res) => {
        dispatch(resetPasswordLoaded())
        if (err) {
          dispatch(setInvalidPasswordRecoveryAttempt(emailAddress))
          return errorHandler(new Error(err.message))
        }
        dispatch(setPasswordRecoveryIdentifier(emailAddress))
      }
    )
  }
}

/**
 * Sets invalidLoginAttempt so that a banner can be displayed letting
 * users know if they have incorrect username / password combos
 */
function setInvalidLoginAttempt() {
  return {
    type: t.SET_INVALID_LOGIN_ATTEMPT
  }
}

/**
 * Resets invalidLoginAttempt so a banner alerting the user of an invalid
 * login attempt will be reset
 */
function resetInvalidLoginAttempt() {
  return {
    type: t.RESET_INVALID_LOGIN_ATTEMPT
  }
}

/**
 * Sets invalidPasswordRecoveryAttempt so that a banner can be displayed letting
 * users know if they have tried to recover a password for a non-existent email/phone
 */
function setInvalidPasswordRecoveryAttempt(identifier) {
  return {
    type: t.SET_INVALID_PASSWORD_RECOVERY_ATTEMPT,
    identifier
  }
}

/**
 * Resets invalidPasswordRecoveryAttempt so a banner alerting the user of an
 * invalid password recovery attempt will be reset
 */
function resetInvalidPasswordRecoveryAttempt() {
  return {
    type: t.RESET_INVALID_PASSWORD_RECOVERY_ATTEMPT
  }
}

/**
 * Sets passwordRecoveryIdentifier
 */
function setPasswordRecoveryIdentifier(identifier) {
  return {
    type: t.SET_PASSWORD_RECOVERY_IDENTIFIER,
    identifier
  }
}

/**
 * Resets passwordRecoveryIdentifier
 */
function resetPasswordRecoveryIdentifier() {
  return {
    type: t.RESET_PASSWORD_RECOVERY_IDENTIFIER
  }
}

function resetExistingSignupIdentifier() {
  return {
    type: t.RESET_EXISTING_SIGNUP_IDENTIFIER
  }
}

function resetError() {
  return {
    type: t.RESET_ERROR
  }
}

// //////////////////////////////
/*          SIGNUP           */
// /////////////////////////////

/**
 * Handle sign up
 * @param  {string} username  email
 * @param  {string} password  password
 * @param  {string} zipCode   zip code
 * @param  {string} referralCode promo code (optional)
 */
function signup({ payload, onSuccess = noop, onError = noop }) {
  const { firstName = null, lastName = null, email, password, zipCode, agreementAccepted, promoCode } = payload

  return (dispatch) => {
    dispatch(signupLoading())
    let params = { firstName, lastName, email, password, zipCode, agreementAccepted, origin: EAZE_ORIGIN }
    if (promoCode) {
      params = Object.assign({}, params, { referralCode: promoCode })
    }

    api.signup(params, (signupErr, res) => {
      dispatch(signupLoaded())

      if (signupErr) {
        if (isStringMatch(signupErr.message, ERRORS.EXISTING_EMAIL)) {
          dispatch(setAlertTypeVisibility(alertTypes.EXISTING_EMAIL_ADDRESS))
        }

        onError(signupErr)
      } else {
        dispatch(receiveLoginSuccess(res))

        track('Signup.Success', {
          userId: res.id
        })

        onSuccess()
      }
    })
  }
}

/**
 Verify the code received by SMS
 * @param {String}    code The verification code to validate
 * @param {function}  onSuccess the callback for a successful validation
 * @param {function}  onError the callback for validation error
*/
function confirmPhoneCode({ code, onSuccess, onError }) {
  return (dispatch, getState) => {
    dispatch(phoneConfirmLoading())
    dispatch(setPhoneConfirmError(false)) // Set error to false during "loading". Set error to true if error.
    const state = getState()
    const { verification, profile } = state
    const mobilePhone = parser(
      verification.phone.phoneNumber || profile.status.phoneNumber || profile.basic.mobilePhone
    )

    api.confirmPhoneNumberVerification(
      { verificationCode: code, verificationType: 'sms', to: mobilePhone },
      (err, res) => {
        if (err) {
          dispatch(phoneConfirmLoaded()) // stop loading animation
          track('Signup.VerificationCode.ConfirmationError')
          dispatch(setPhoneConfirmError(true))
          onError(err) // ONLY LOGS ERROR. Defined in pages/signup.js `errorHandler`
        } else {
          track('Signup.VerificationCode.Success')
          dispatch(
            fetchStatus(() => {
              // get the new user status
              dispatch(phoneConfirmLoaded()) // stop loading animation
              onSuccess() // move to next step in flow - this might be redundant since the user status is updated
            })
          )
        }
      }
    )
  }
}

function setPhoneConfirmError(isError) {
  return {
    type: t.PHONE_CONFIRM_ERROR,
    payload: isError
  }
}

function handleSignupLocation(zipLocation) {
  return async (dispatch, getState) => {
    const state = getState()
    const placeId = zipLocation.id
    dispatch(setAlertTypeVisibility(alertTypes.INVALID_STREET_ADDRESS, false))
    dispatch(setAlertTypeVisibility(alertTypes.LOCATION_OUT_OF_SERVICE, false))
    dispatch(addressLoading())

    const address = await locationFetcher(placeId)

    dispatch(handleAddressSubmissionChange(address))

    dispatch(addressLoaded())

    if (isLoggedIn(state)) {
      dispatch(fetchCart())
    }
  }
}

// //////////////////////////////
/*        ID / MMID          */
// /////////////////////////////

/*
  Upload a file and handle error and success dispatch
  @param  {FormData} file  File to upload
  @param  {Func} errCb     Error callback
  @param  {Func} sucCb     Success callback
*/
function upload({ file, onError, onSuccess }) {
  api.upload(file, (err, res) => {
    if (err) {
      onError(err)
      return
    }
    onSuccess(res)
  })
}

/*
  @param  {FormData} file The file to upload wrapped in a FormData
*/
function uploadIdentification({ isRec = false, file, onError = noop, onSuccess = noop }) {
  return (dispatch) => {
    // TODO: Handle upload errors.
    // onAlert({type: 'IMAGE_UPLOAD_ERROR')
    // dispatch(setAlertTypeVisibility(alertTypes.IMAGE_UPLOAD_ERROR))
    isRec ? dispatch(mmidImageLoading()) : dispatch(stateIdUploading())

    track('Signup.StateId.ConfirmUpload') // track when a user clicks the "looks good!" button
    upload({
      file,
      onError: (err) => {
        isRec ? dispatch(mmidImageLoaded()) : dispatch(stateIdUploaded())
        onError(err)
      },
      onSuccess: (res) => {
        dispatch(idUploadSuccess(isRec, res))
        onSuccess(res)
      }
    })
  }
}

/*
  Set the uploaded photo id in the User object
  @param  {String} id The id of the photo uploaded
*/
function identificationPostPhoto(id) {
  return (dispatch, getState) => {
    track('Verification.Id.Submit')
    const state = getState()
    const userId = getUserId(state)
    const email = getUserEmail(state)

    if (!userId || !email) return console.warn('Missing userId || email for identificationPostPhoto')

    api.writeBasicUserInfo(
      {
        contentId: id,
        email,
        userId
      },
      () => {
        // if (err) return dispatch(identificationError(err.message))
        dispatch(setIdentificationPhoto(id))
        dispatch(stateIdUploaded())
      }
    )
  }
}

/*
  Set the uploaded photo id in the User object
  @param  {String} id The id of the photo uploaded
*/
function recommendationPostPhoto(id) {
  return (dispatch, getState) => {
    track('Verification.Medical.Submit')
    const userId = getUserId(getState())

    api.verifyRecommendation(
      {
        contentId: id,
        // The api requires to pass a type of recommendation. Passing 2 for Letter of Recommendation as default. Hopefully the API will soon mark this field as optional.
        eligibilityProofType: 2,
        userId
      },
      () => {
        dispatch(setRecommendationPhoto(id))
        dispatch(mmidImageLoaded())
      }
    )
  }
}

function idUploadSuccess(isRec = false, res) {
  if (Array.isArray(res)) {
    // API returns an array
    res = res[0]
  }
  return (dispatch) => {
    isRec ? dispatch(recommendationPostPhoto(res.id)) : dispatch(identificationPostPhoto(res.id))
  }
}

// we are using this as a quick solution to log a user out
// if their token is invalid. We are leveraging the sso endpoint
// to do this. This was being used in the page hoc and
// would be run every page load, currently this is disabled and we are
// logging the user out if a 401 from a /api/users endpoint
function verifyAuthToken(callback) {
  return (dispatch, getState) => {
    const {
      user: { xAuthToken }
    } = getState()

    if (xAuthToken) {
      api.sso({ xAuthToken }, callback)
    }
  }
}

export function attemptEmailChange(payload) {
  return {
    type: t.ATTEMPT_EMAIL_CHANGE,
    payload
  }
}

export function attemptIdChange() {
  return {
    type: t.ATTEMPT_ID_CHANGE
  }
}

export function attemptPhoneChange(payload) {
  return {
    type: t.ATTEMPT_PHONE_CHANGE,
    payload
  }
}

export function resetProfileChange() {
  return {
    type: t.RESET_PROFILE_CHANGE
  }
}

export function dismiss2faModal() {
  return (dispatch) => {
    dispatch(fetchUserProfile())
    dispatch(resetCurrentAction())
    dispatch(resetProfileChange())
  }
}
